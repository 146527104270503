import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* max-width: 1200px; */
  margin: 4rem auto;
  padding: 0 200px;

  @media (max-width: 1921px) {
    padding: 0 150px;
  }

  @media (max-width: 1681px) {
    padding: 0 100px;
  }

  @media (max-width: 1025px) {
    padding: 0 40px;
  }

  > * {
    font-family: 'Roboto', sans-serif;
  }

  h1,
  h2,
  h3 {
    font-weight: bold;
    margin-block: 1rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  p + p {
    margin-top: 1rem;
  }

  ol,
  ul {
    list-style: unset;
    padding-left: 1rem;
    margin-block: 1rem;
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style: lower-roman;
    }

    ul {
      list-style: initial;
    }
  }

  p + ul,
  p + ol {
    margin-top: 1rem;
  }

  a {
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
  }

  .card {
    border: 1px solid black;
    background: lightgray;
    padding: 1rem;
    display: grid;
    column-gap: 1rem;

    h4 {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    @media (min-width: 40em) {
      grid-template-columns: 1fr 1fr;

      h3 {
        grid-column: span 2;
      }
    }

    & + .card {
      margin-top: 1rem;
    }
  }
`;
