import * as React from 'react';
import WeDash from '../../assets/img/logo.svg';
import WhiteWeDash from '../../assets/img/white_logo.svg';
import { Footer, Header } from '../../components';
import { headerOptions } from './mockProps';
import * as S from './styled';

const Contact = () => {
  return (
    <>
      <Header
        logo={WeDash}
        navLinks={headerOptions}
        navButton="https://app.wedash.digital/"
        contactsPage
      />
      <S.Container>
        <section>
          <h2>
            PARA VOCÊ ENTENDER MELHOR O QUE FAZEMOS COM AS SUAS INFORMAÇÕES
          </h2>
          <p>
            Fizemos o máximo para explicar de forma clara e simples quais dados
            pessoais precisaremos de você e o que vamos fazer com cada um deles.
            Por isso, separamos abaixo os pontos mais importantes, que também
            podem ser lidos de forma bem completa e detalhada nas nossas
            Políticas de Privacidade.
          </p>

          <p>
            Além disso, estamos sempre disponíveis para tirar qualquer dúvida
            que você tenha por meio do e-mail{' '}
            <a href="mailto:contato@wedash.digital">contato@wedash.digital</a>.
          </p>

          <ol>
            <li>
              <h3>Como faremos a segurança de seus dados?</h3>
              <p>
                O<strong> Wedash </strong>consiste em uma plataforma de Business
                Intelligence (BI) que compila dados dos principais canais de
                mídia do nosso cliente, para isso, se preocupa muito com a
                segurança de seus dados pessoais. Por isso, implementa todas as
                medidas sugeridas pela Autoridade Nacional de Proteção de Dados
                (ANPD) em seu Guia Orientativo para Agentes de Tratamento de
                Pequeno Porte, o que compreende uma variedade de tecnologias e
                procedimentos de segurança para ajudar a proteger as suas
                informações. Para utilização da Plataforma, os Usuários
                realizarão seus respectivos cadastros, sendo os responsáveis
                pela veracidade dos dados cadastrados, quais sejam: Razão
                Social, CNPJ, nome completo do responsável e e-mail.
              </p>
              <p>
                Para garantir que suas informações são armazenadas em ambiente
                seguro, a plataforma utiliza os servidores{' '}
                <a href="https://aws.amazon.com/pt/">AWS Amazon</a>, cujo acesso
                é restrito.{' '}
                <u>
                  Com isso, essa empresa passa a ter acesso aos seus dados
                  somente para armazená-los assim que você os fornece no Wedash.
                </u>
                Além disso, o uso deste servidor pode implicar em uma
                transferência internacional dos seus dados pessoais para o país
                sede do servidor, Estados Unidos.
              </p>
            </li>
            <li>
              <h3>
                Como faremos a segurança e processamento dos seus dados de
                pagamento?
              </h3>
              <p>
                Para o processamento das informações referentes aos pagamentos,
                os seus dados serão enviados via API e trafegados pela
                plataforma independentes{' '}
                <a href="https://www.asaas.com/">Asaas</a>, que é a instituição
                responsável pelo processamento dos pagamentos. Nós não teremos
                acesso aos seus dados bancários e utilizamos essa plataforma
                terceirizada para que eles possam garantir a segurança nas
                transações financeiras, já que sozinhos não conseguiríamos.
              </p>
            </li>
            <li>
              <h3>De quem são os dados coletados pela plataforma?</h3>
              <p>
                Os dados coletados serão dos clientes e usuários da plataforma.
              </p>
            </li>

            <li>
              <h3>
                Quais dados você precisa nos informar ao se cadastrar na
                plataforma?
              </h3>
              <p>
                Quando os Usuários se cadastrarem na plataforma iremos pedir as
                seguintes informações:
              </p>
              <ul>
                <li>Para Usuário Administrador: Nome e e-mail.</li>
                <li>
                  Para Usuário Cliente: Razão Social, CNPJ, nome completo do
                  responsável e e-mail.
                </li>
              </ul>
            </li>

            <li>
              <h3>
                Quem é o responsável pelo tratamento dos seus dados pessoais?
              </h3>
              <p>
                O<strong> Wedash </strong>é operador do tratamento de dados
                pessoais ligados à empresa que contrata seus serviços. A
                plataforma apenas fornece as ferramentas para a integração dos
                dados do desempenho de marketing e vendas dos canais digitais
                dos Usuários Clientes, para maior performance nos negócios, o
                que permite a visualização de dados de várias plataformas
                distintas ao mesmo tempo. Ou seja, através das métricas de
                desempenho de marketing dos principais canais de mídia, a
                plataforma disponibilizará uma melhor visualização da
                performance do cliente.
              </p>
              <p>
                O Usuário tem a possibilidade de inserir seus respectivos dados
                pessoais na plataforma<strong> Wedash </strong>por meio da
                integração das suas bases de dados existentes nas plataformas{' '}
                <a href="https://ads.google.com/intl/pt-BR_br/home/">
                  Google Ads
                </a>
                ,{' '}
                <a href="https://analytics.google.com/analytics/web/">
                  Google Analytics
                </a>
                ,{' '}
                <a href="https://pt-br.facebook.com/business/ads">
                  Facebook Ads
                </a>
                ,{' '}
                <a href="https://business.linkedin.com/pt-br/marketing-solutions/ads">
                  Linkedin Ads
                </a>
                , <a href="https://www.pipedrive.com/pt">Pipedrive</a>,{' '}
                <a href="https://www.rdstation.com/crm/">RD Station</a>,{' '}
                <a href="https://br.hubspot.com/">HubSpot</a> e{' '}
                <a href="https://www.ploomes.com/">Ploomes</a>.
              </p>
              <p>
                O<strong> Wedash </strong>poderá tratar esses dados pessoais
                inseridos na plataforma pela contratante (Usuário Cliente) de
                forma padrão, durante a sua jornada de uso. Contudo, a
                contratante (Usuário Cliente) é a controladora do tratamento e
                tem o poder de decidir o que fazer com esses dados. O
                <strong> Wedash </strong>não realiza qualquer tipo de tratamento
                desses dados inseridos pela contratante para fins próprios ou
                diversos do que foi contratada para realizar.
              </p>
              <p>
                Em algumas situações, o<strong> Wedash </strong>pode ser
                considerado controlador do tratamento, principalmente no que diz
                respeito aos dados coletados da contratante (Usuário Cliente)
                para prestar o serviço contratado, conforme estas Políticas de
                Privacidade.
              </p>
            </li>

            <li>
              <h3>Com quem compartilhamos seus dados pessoais?</h3>
              <p>
                A empresa contratante do Wedash, como controladora do tratamento
                de dados pessoais inseridos em sua conta, vinculados à ela, tem
                acesso e poder de decisão sobre o tratamento das informações que
                estes inserirem na plataforma. A plataforma atuará somente como
                operadora dos dados, na medida em que disponibiliza a base para
                que os Usuários Clientes integrem seus desempenhos de mídia.
              </p>
              <p>
                A empresa contratante, por meio de seu Usuário Administrador, se
                declara ciente que é responsável pela segurança, tratamento e
                compartilhamento adequado dessas informações, não podendo
                realizar seu tratamento em desconformidade com a legislação
                vigente ou com estas Políticas de Privacidade, sob pena de
                responderem por todas as punições, em especial as de natureza
                cível, criminal e as aplicadas pela Autoridade Nacional de
                Proteção de Dados.
              </p>
              <p>
                Além disso, o Wedash, salvo nos casos citados nestas Políticas,
                em caso de consentimento legal do titular dos dados pessoais e
                por força de ordem judicial ou determinação legal, não
                compartilha seus dados com terceiros.
              </p>
            </li>

            <li>
              <h3>Seus registros de acesso serão coletados?</h3>
              <p>
                Quando você entra na nossa plataforma, colhemos seus registros
                de acesso, ou seja, conjunto de informações referentes à data e
                hora de uso de uma determinada aplicação de internet a partir de
                um determinado endereço IP, estas informações serão mantidas
                pelo Wedash, sob sigilo, em ambiente controlado e de segurança,
                pelo prazo mínimo de 06 (seis) meses, nos termos da Lei n.
                12.965/2014, e artigo 7º, II, da Lei n. 13.709/18.
              </p>
            </li>

            <li>
              <h3>Registros de comunicações serão armazenados?</h3>
              <p>
                Nós vamos armazenar todas as conversas que você tiver conosco em
                nossos canais de comunicação, pois isso irá melhorar o seu
                atendimento e torná-lo mais eficiente. Sem esse histórico,
                provavelmente todas as vezes que você utilizasse a plataforma
                teria que repetir o que já nos passou anteriormente.
              </p>
            </li>

            <li>
              <h3>Como seus dados serão tratados?</h3>
              <p>
                Todos os seus dados são tratados com finalidades específicas e
                de acordo com a Lei de Proteção de Dados Pessoais. Todas essas
                informações estão descritas em uma tabela, para facilitar a sua
                visualização, nas nossas Políticas de Privacidade.
              </p>
            </li>

            <li>
              <h3>Quais são seus direitos?</h3>
              <p>
                Mesmo que você já tenha nos fornecido seus dados pessoais, você
                possui total direito de, a qualquer momento: confirmar a
                existência de tratamento dos seus dados; acessar os seus dados;
                corrigir os seus dados; tornar anônimo os dados; bloquear ou
                eliminar os dados desnecessários, excessivos ou tratados em
                desconformidade com a Lei; pedir a portabilidade dos dados a
                outro fornecedor; eliminar dados, exceto aqueles exigidos por
                lei; obter informação sobre quem o<strong> Wedash </strong>
                realizou uso compartilhado de dados; obter informação sobre a
                possibilidade de não fornecer consentimento e sobre as
                consequências da negativa; e voltar atrás e revogar o seu
                consentimento.
              </p>
            </li>

            <li>
              <h3>Essas condições poderão mudar?</h3>
              <p>
                Nossa Política de Privacidade poderá mudar, mas você sempre
                poderá acessar a versão mais atualizada na nossa plataforma.
                Além disso, se formos realizar alguma ação que a lei exija sua
                autorização, você receberá um aviso antes para poder aceitar ou
                recusar.
              </p>
            </li>

            <li>
              <h3>Qual é o conteúdo das Políticas de Privacidade?</h3>
              <p>
                A Política de Privacidade a seguir está dividida da seguinte
                forma para facilitar o seu acesso à informação:
              </p>
              <ul>
                <li>Data de Disponibilização do Texto;</li>
                <li>
                  Explicação dos Termos Técnicos ou em Língua Estrangeira;
                </li>
                <li>
                  Privacidade do Usuário e Operadores de Dados Terceirizados;
                </li>
                <li>Coleta de Dados;</li>
                <li>Tratamento de Dados Pessoais;</li>
                <li>Compartilhamento dos Dados Pessoais dos Usuários;</li>
                <li>
                  Cancelamento da Plataforma, de Contas de Acesso e Exclusão de
                  Dados;
                </li>
                <li>Direitos do Titular dos Dados Pessoais;</li>
                <li>Links para Sites de Terceiros;</li>
                <li>Mudanças na Política de Privacidade;</li>
                <li>
                  Encarregado e Disposições Gerais; [ou] Canal de Comunicação
                  sobre Privacidade e Disposições Gerais;
                </li>
                <li>Contato. </li>
              </ul>
              <p>
                <strong>Wedash.</strong>
              </p>
            </li>
          </ol>
        </section>

        <hr />

        <section>
          <h2>POLÍTICAS DE PRIVACIDADE</h2>
          <p>
            Antes de acessar a plataforma do Wedash, é importante que você leia,
            entenda e aceite de forma livre, inequívoca e informada estas
            Políticas de Privacidade.
          </p>
          <p>
            Esta plataforma, cujo nome é <strong>Wedash</strong>, é de
            propriedade, mantida e operada por <strong>BOWE LTDA</strong>,
            pessoa jurídica constituída na forma de sociedade empresária
            Limitada, inscrita no CNPJ sob o nº 19.463.933/0001-43, com sede na
            Avenida Belarmino Cotta Pacheco, nº 08, Sala 01, Bairro Santa
            Mônica, CEP: 38.408-168, na cidade de Uberlândia, estado de Minas
            Gerais.
          </p>
          <p>
            O presente documento visa prestar informações sobre a coleta,
            utilização, tratamento e armazenamento dos dados fornecidos pelos
            Usuários e está em conformidade com a Lei nº 12.965/2014 (Marco
            Civil da Internet) e com a Lei nº 13.709/18 (Lei Geral de Proteção
            de Dados).
          </p>

          <ol>
            <li>
              <h3>DATA DE DISPONIBILIZAÇÃO DO TEXTO</h3>
              <ol>
                <li>
                  A presente versão deste documento foi disponibilizada em:
                  04/03/2022
                </li>
              </ol>
            </li>

            <li>
              <h3>EXPLICAÇÃO DOS TERMOS TÉCNICOS OU EM LÍNGUA ESTRANGEIRA</h3>
              <ol>
                <li>
                  Abaixo estão dispostos os significados das nomenclaturas
                  técnicas e termos na língua inglesa:
                  <ul>
                    <li>
                      Controlador: Pessoa natural ou jurídica, de direito
                      público ou privado, a quem competem as decisões referentes
                      ao tratamento de dados pessoais;
                    </li>
                    <li>
                      Cookies: Pequenos arquivos de texto que ficam gravados no
                      computador do internauta e podem ser recuperados pelo site
                      que o enviou durante a navegação. São utilizados
                      principalmente para identificar e armazenar informações
                      sobre os visitantes.
                    </li>
                    <li>
                      Criptografia: Conjunto de princípios e técnicas para
                      cifrar a escrita, torná-la ininteligível para os que não
                      tenham acesso às convenções combinadas.
                    </li>
                    <li>
                      Dado pessoal: Informação relacionada a pessoa natural
                      identificada ou identificável;
                    </li>
                    <li>
                      Dado pessoal sensível: Dado pessoal sobre origem racial ou
                      étnica, convicção religiosa, opinião política, filiação a
                      sindicato ou a organização de caráter religioso,
                      filosófico ou político, dado referente à saúde ou à vida
                      sexual, dado genético ou biométrico, quando vinculado a
                      uma pessoa natural;
                    </li>
                    <li>
                      Encarregado: Pessoa indicada pelo controlador e operador
                      para atuar como canal de comunicação entre o controlador,
                      os titulares dos dados e a Autoridade Nacional de Proteção
                      de Dados (ANPD).
                    </li>
                    <li>
                      IP (ou Internet Protocol): Identificação única para cada
                      computador conectado a uma rede.
                    </li>
                    <li>
                      Operador: Pessoa natural ou jurídica, de direito público
                      ou privado, que realiza o tratamento de dados pessoais em
                      nome do controlador;
                    </li>
                    <li>
                      Tratamento de dados: Toda operação realizada com dados
                      pessoais, como as que se referem a coleta, produção,
                      recepção, classificação, utilização, acesso, reprodução,
                      transmissão, distribuição, processamento, arquivamento,
                      armazenamento, eliminação, avaliação ou controle da
                      informação, modificação, comunicação, transferência,
                      difusão ou extração.
                    </li>
                  </ul>
                </li>

                <li>
                  <p>São os tipos de Usuários da plataforma:</p>
                  <ul>
                    <li>
                      Usuário Administrador: Aquele que administrará a
                      plataforma atuando em nome do Wedash.
                    </li>
                    <li>
                      Usuário Cliente: Aquele que adquire os produtos e serviços
                      do Wedash, por meio do plano escolhido, podendo ainda,
                      avaliar os produtos e serviços oferecidos.
                    </li>
                    <li>
                      Estes Usuários, em conjunto, serão chamados apenas de
                      Usuários.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>

            <li>
              <h3>
                PRIVACIDADE DO USUÁRIO E OPERADORES DE DADOS TERCEIRIZADOS
              </h3>
              <ol>
                <li>
                  O Wedash se preocupa muito com a segurança dos dados pessoais
                  de seus Usuários. Por isso, implementa todas as medidas
                  sugeridas pela Autoridade Nacional de Proteção de Dados (ANPD)
                  em seu Guia Orientativo para Agentes de Tratamento de Pequeno
                  Porte, o que compreende uma variedade de tecnologias e
                  procedimentos de segurança para ajudar a proteger as suas
                  informações.
                </li>
                <li>
                  Para garantir que informações dos Usuários sejam armazenadas
                  em ambiente seguro, a plataforma utiliza os servidores da{' '}
                  <a href="https://aws.amazon.com/pt/">AWS Amazon</a>, cujo
                  acesso é restrito. Com isso, essa empresa passa a ter acesso
                  aos seus dados somente para armazená-los assim que o Usuário
                  os fornece no <strong>Wedash</strong>.
                  <ol>
                    <li>
                      Esses servidores são independentes e não possuem relação
                      com o presente texto. Sendo assim, recomendamos que o
                      Usuário também leia os termos de uso e políticas de
                      privacidade dessas plataformas e veja se concorda com
                      todas as disposições antes de utilizar nossos serviços.
                    </li>
                    <li>
                      Para o processamento das informações referentes aos
                      pagamentos, os seus dados serão enviados via API e
                      trafegados pela plataforma independentes{' '}
                      <a href="https://www.asaas.com/">Asaas</a>, que é a
                      instituição responsável pelo processamento dos pagamentos.
                      Nós não teremos acesso aos seus dados bancários e
                      utilizamos essa plataforma terceirizada para que eles
                      possam garantir a segurança nas transações financeiras, já
                      que sozinhos não conseguiríamos. Também recomendamos que
                      você leia as
                      <a href="https://ajuda.asaas.com/pt-BR/articles/102029-politica-de-privacidade">
                        Políticas de Privacidade
                      </a>{' '}
                      e os{' '}
                      <a href="https://ajuda.asaas.com/pt-BR/articles/102021-termos-e-condicoes-de-uso">
                        Termos de Uso
                      </a>
                      do <a href="https://www.asaas.com/">Asaas</a> antes de
                      realizar os pagamentos.
                    </li>
                  </ol>
                </li>
                <li>
                  Os servidores utilizados pela <strong>Wedash</strong> são
                  munidos de mecanismos aptos a assegurar a segurança de seus
                  dados, estão localizados fora do Brasil, nos Estados Unidos, e
                  são utilizados para que a <strong>Wedash</strong> possa
                  executar o seu serviço de forma adequada, conforme artigo 33,
                  IX da Lei n. 13709/18.
                </li>
                <li>
                  Todos os registros de acesso, conjunto de informações
                  referentes à data e hora de uso de uma determinada aplicação
                  de internet a partir de um determinado endereço IP, serão
                  mantidos pelo <strong>Wedash</strong>, sob sigilo, em ambiente
                  controlado e de segurança, pelo prazo mínimo de 06 (seis)
                  meses, nos termos da Lei n. 12.965/2014, e artigo 7º, II, da
                  Lei n. 13.709/18.
                </li>
                <li>
                  O Usuário deve se responsabilizar e se declarar exclusivo
                  responsável por todas as ações, bem como por todas as
                  informações e a veracidade do conteúdo que inserir na
                  plataforma.
                </li>
              </ol>
            </li>

            <li>
              <h3>COLETA DE DADOS</h3>
              <ol>
                <li>
                  Dados fornecidos pelo Usuário:
                  <ol>
                    <li>Para Usuário Administrador: Nome e e-mail.</li>
                    <li>
                      Para Usuário Cliente: Razão Social, CNPJ, nome completo do
                      responsável e e-mail.
                    </li>
                  </ol>
                </li>

                <li>
                  Histórico de contato: Nós vamos armazenar todas as conversas
                  que você tiver conosco em nossos canais de comunicação, pois
                  isso irá melhorar o seu atendimento e torná-lo mais eficiente.
                  Sem esse histórico, provavelmente todas as vezes que você
                  utilizasse a plataforma teria que repetir o que já nos passou
                  anteriormente.
                </li>

                <li>
                  Agentes de Tratamento: O <strong>Wedash</strong> é operadora
                  do tratamento de dados pessoais inseridos pela empresa que
                  contrata seus serviços. A plataforma apenas fornece as
                  ferramentas para a integração dos dados do desempenho de
                  marketing e vendas dos canais digitais dos Usuários Clientes,
                  para maior performance nos negócios, o que permite a
                  visualização de dados de várias plataformas distintas ao mesmo
                  tempo. Por isso, o <strong>Wedash</strong> realiza todo o
                  tratamento desses dados inseridos com base nas decisões desta
                  empresa. No caso descrito, a contratante do{' '}
                  <strong>Wedash</strong> é a controladora do tratamento de
                  dados pessoais que insere na plataforma.
                  <ol>
                    <li>
                      A plataforma poderá tratar dados pessoais inseridos pela
                      contratante de forma padrão, durante a sua jornada de uso.
                      Contudo, é a contratante (Usuário Cliente), que tem o
                      poder de decidir o que fazer com essas informações. O
                      <strong>Wedash</strong> não realiza qualquer tipo de
                      tratamento dos dados inseridos pelo Usuário para fins
                      próprios ou diversos do que foi contratado para fazer.
                    </li>

                    <li>
                      O Usuário tem a possibilidade de inserir dados pessoais na
                      plataforma Wedash por meio da integração das suas bases de
                      dados existentes nas plataformas{' '}
                      <a href="https://ads.google.com/intl/pt-BR_br/home/">
                        Google Ads
                      </a>
                      ,{' '}
                      <a href="https://analytics.google.com/analytics/web/">
                        Google Analytics
                      </a>
                      ,{' '}
                      <a href="https://pt-br.facebook.com/business/ads">
                        Facebook Ads
                      </a>
                      ,{' '}
                      <a href="https://business.linkedin.com/pt-br/marketing-solutions/ads">
                        Linkedin Ads
                      </a>
                      , <a href="https://www.pipedrive.com/pt">Pipedrive</a>,{' '}
                      <a href="https://www.rdstation.com/crm/">RD Station</a>,{' '}
                      <a href="https://br.hubspot.com/">HubSpot</a> e{' '}
                      <a href="https://www.ploomes.com/">Ploomes</a>. É de
                      responsabilidade do Usuário Cliente, como controlador,
                      informar ao titular desses dados as finalidades para que
                      utiliza os dados pessoais, assim como atribuir as bases
                      legais respectivas.
                    </li>
                    <li>
                      O <strong>Wedash</strong>, em algumas situações, pode ser
                      considerada controladora do tratamento, principalmente no
                      que diz respeito aos dados coletados da contratante
                      (Usuário Cliente) para prestar o serviço contratado,
                      conforme estas Políticas de Privacidade.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h3>TRATAMENTO DE DADOS PESSOAIS</h3>

              <ol>
                <li>
                  Ao aceitar a presente política de privacidade, o Usuário
                  compreende que a plataforma realiza o tratamento de seus dados
                  pessoais, conforme informações apresentadas a seguir:
                </li>
                <div className="card">
                  <h3>Razão social / Nome do responsável</h3>
                  <div>
                    <h4>Base Legal</h4>
                    <p>
                      a) Cumprimento de obrigação legal ou regulatória pelo
                      controlador (Art. 7º, II, Lei nº 13.709/2018).{' '}
                    </p>
                    <p>
                      b) Necessário para o cumprimento do legítimo interesse do
                      controlador (Art. 7, IX, Lei nº 13.709/2018).
                    </p>
                    <p>
                      c) Necessário para a execução de contrato ou de
                      procedimentos preliminares relacionados a contrato do qual
                      seja parte o titular, a pedido do titular dos dados (Art.
                      7º, V, Lei nº 13.709/2018).
                    </p>
                  </div>
                  <div>
                    <h4>Finalidade</h4>
                    <p>
                      a) Utilizado para emissão de nota fiscal e cobrança ao
                      Usuário Cliente e compartilhando, se for o caso, com o
                      responsável por essa emissão.
                    </p>
                    <p>
                      b) Utilizado para evitar duplicidades cadastrais na
                      plataforma, como forma de controle antifraude.
                    </p>
                    <p>
                      c) Utilizado como meio de comunicação com o Usuário
                      Cliente.
                    </p>
                  </div>
                </div>

                <div className="card">
                  <h3>CNPJ</h3>
                  <div>
                    <h4>Base Legal</h4>
                    <p>
                      a) Cumprimento de obrigação legal ou regulatória pelo
                      controlador (Art. 7º, II, Lei nº 13.709/2018).
                    </p>

                    <p>
                      b) Necessário para o cumprimento do legítimo interesse do
                      controlador (Art. 7, IX, Lei nº 13.709/2018).
                    </p>
                    <p>
                      c) Necessário para a execução de contrato ou de
                      procedimentos preliminares relacionados a contrato do qual
                      seja parte o titular, a pedido do titular dos dados (Art.
                      7º, V, Lei nº 13.709/2018).
                    </p>
                  </div>
                  <div>
                    <h4>Finalidade</h4>

                    <p>
                      {' '}
                      a) Utilizado para emissão de nota fiscal e cobrança ao
                      Usuário Cliente e compartilhando, se for o caso, com o
                      responsável por essa emissão.
                    </p>
                    <p>
                      b) Utilizado para evitar duplicidades cadastrais na
                      plataforma, como forma de controle antifraude.
                    </p>

                    <p>
                      c) Utilizado como meio de comunicação com o Usuário
                      Cliente.
                    </p>
                  </div>
                </div>

                <div className="card">
                  <h3>E-mail</h3>
                  <div>
                    <h4>Base Legal</h4>
                    <p>
                      a) Necessário para a execução de contrato ou de
                      procedimentos preliminares relacionados a contrato do qual
                      seja parte o titular, a pedido do titular dos dados (Art.
                      7º, V, Lei nº 13.709/2018).
                    </p>
                  </div>
                  <div>
                    <h4>Finalidade</h4>
                    <p>
                      a) Utilizado para login e validação de cadastro na
                      plataforma, assim como meio de comunicação com o Usuário
                      Cliente.
                    </p>
                  </div>
                </div>

                <div className="card">
                  <h3>Registros de acesso</h3>
                  <div>
                    <h4>Base Legal</h4>
                    <p>
                      Cumprimento de obrigação legal ou regulatória pelo
                      controlador (Art. 7º, II, Lei nº 13.709/2018).
                    </p>
                  </div>
                  <div>
                    <h4>Finalidade</h4>
                    <p>
                      Obediência ao artigo 15 da Lei n. 12.965/2014, que impõe o
                      dever do Wedash de manter os respectivos registros de
                      acesso a aplicações de internet, sob sigilo, em ambiente
                      controlado e de segurança, pelo prazo de 6 (seis) meses.
                    </p>
                  </div>
                </div>
              </ol>
            </li>

            <li>
              <h3>COMPARTILHAMENTO DOS DADOS DOS USUÁRIOS </h3>

              <ol>
                <li>
                  Poderão ter acesso interno às informações dos Usuários apenas
                  pessoas estritamente necessárias para a prestação do serviço,
                  como a equipe de BackOffice, a equipe de BI e Tecnologia, a
                  equipe de Produto e a equipe de Vendas.
                </li>

                <li>
                  Os dados dos Usuários serão compartilhados pelo Wedash com as
                  seguintes empresas e para as seguintes finalidades:
                  <ol>
                    <li>
                      <a href="https://aws.amazon.com/pt/">AWS Amazon</a>: uma
                      vez que os dados pessoais ficam armazenados em seus
                      servidores;
                    </li>
                    <li>
                      <a href="https://www.asaas.com/">Asaas</a>: uma vez que
                      são responsáveis pelo processamento dos pagamentos.
                    </li>
                    <li>
                      A partir do momento que estas empresas tiverem acesso a
                      estes dados, se tornarão responsáveis pela segurança,
                      tratamento e compartilhamento adequado dessas informações,
                      não podendo divulgá-las para outras finalidades, em
                      desconformidade com a legislação vigente ou com estas
                      Políticas de Privacidade, sob pena de responderem por
                      todas as punições, em especial as de natureza cível,
                      criminal e as aplicadas pela Autoridade Nacional de
                      Proteção de Dados.
                    </li>
                  </ol>
                </li>

                <li>
                  Salvo nos casos citados, em caso de consentimento legal do
                  titular dos dados pessoais e por força de ordem judicial ou
                  determinação legal, o Wedash não irá compartilhar seus dados
                  com terceiros.
                </li>

                <li>
                  cesso pela controladora: A empresa contratante do Wedash, como
                  controladora do tratamento de dados pessoais vinculados à ela,
                  tem acesso e poder de decisão sobre o tratamento das
                  informações que estes inserirem na plataforma.{' '}
                  <ol>
                    <li>
                      A empresa contratante, por meio de seu Usuário
                      Administrador, se declara ciente que é responsável pela
                      segurança, tratamento e compartilhamento adequado dessas
                      informações, não podendo realizar seu tratamento em
                      desconformidade com a legislação vigente ou com estas
                      Políticas de Privacidade, sob pena de responderem por
                      todas as punições, em especial as de natureza cível,
                      criminal e as aplicadas pela Autoridade Nacional de
                      Proteção de Dados.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h3>
                CANCELAMENTO DA PLATAFORMA, DE CONTAS DE ACESSO E EXCLUSÃO DE
                DADOS{' '}
              </h3>

              <ol>
                <li>
                  Cancelamento de contas de acesso pelo Wedash: O Wedash poderá,
                  a seu exclusivo critério, bloquear, restringir, desabilitar ou
                  impedir o acesso de qualquer Usuário à plataforma sempre que
                  for detectada uma conduta inadequada.
                </li>
                <li>
                  Cancelamento dos serviços pelo Usuário: Para cancelar os
                  serviços o Usuário deverá encaminhar a solicitação de
                  cancelamento para o e-mail{' '}
                  <a href="mailto:contato@wedash.digital">
                    contato@wedash.digital
                  </a>
                  .
                </li>

                <li>
                  <strong>
                    Exclusão de dados: No momento do cancelamento da conta,
                    quando finda a finalidade de tratamento de dados ou diante
                    de solicitação, o Usuário terá todos os seus dados deletados
                    imediatamente e permanentemente da plataforma, exceto os
                    dados cuja manutenção seja obrigatória por lei ou regulação,
                    os dados necessários para o exercício regular de direitos em
                    processo judicial, administrativo ou arbitral, como os
                    registros de acesso (conjunto de informações referentes à
                    data e hora de uso de uma determinada aplicação de internet
                    a partir de um determinado endereço IP), que serão mantidos,
                    sob sigilo, em ambiente controlado e de segurança, pelo
                    prazo de 6 (seis) meses, nos termos da Lei n. 12.965/2014 e
                    com a base legal do art. 7, II, da Lei Geral de Proteção de
                    Dados Pessoais.
                  </strong>

                  <ol>
                    <li>
                      No caso de cancelamento dos serviços pelo Usuário
                      Administrador, em nome da contratante, os dados inseridos
                      na plataforma ficarão disponíveis para download em formato
                      .xls, por 30 (trinta) dias e, após este prazo, serão
                      excluídos permanentemente do banco de dados da plataforma,
                      exceto as informações cuja manutenção seja obrigatória por
                      lei ou regulação.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h3>DIREITOS DO TITULAR DOS DADOS PESSOAIS </h3>
              <ol>
                <li>
                  O titular de dados pessoais tem direito a obter do
                  controlador, em relação aos dados por ele tratados, a qualquer
                  momento e mediante requisição:
                  <ol>
                    <li>Confirmação da existência de tratamento de dados;</li>
                    <li>Acesso aos dados;</li>
                    <li>
                      Correção de dados incompletos, inexatos ou desatualizados;
                    </li>
                    <li>
                      Anonimização, bloqueio ou eliminação de dados
                      desnecessários, excessivos ou tratados em desconformidade
                      com o disposto na Lei 13.709/2018;
                    </li>
                    <li>
                      Portabilidade dos dados a outro fornecedor de serviço ou
                      produto, mediante requisição expressa, de acordo com a
                      regulamentação da autoridade nacional, observados os
                      segredos comercial e industrial;{' '}
                    </li>
                    <li>
                      Eliminação dos dados tratados com o consentimento do
                      titular, exceto nas hipóteses previstas na Lei
                      13.709/2018;{' '}
                    </li>
                    <li>
                      Informação das entidades públicas e privadas com as quais
                      o controlador realizou uso compartilhado de dados;
                    </li>
                    <li>
                      Informação sobre a possibilidade de não fornecer
                      consentimento e sobre as consequências da negativa;
                    </li>
                    <li>Revogação do consentimento.</li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h3>LINKS PARA SITES DE TERCEIROS</h3>
              <ol>
                <li>
                  Nossa plataforma pode conter links de sites de terceiros. Ao
                  seguir um link, o Usuário Cliente deve verificar as políticas
                  de privacidade desses sites, pois não aceitamos qualquer
                  responsabilidade ou obrigação por essas políticas. Por favor,
                  verifique essas políticas antes de compartilhar quaisquer
                  dados pessoais com esses sites.{' '}
                </li>
              </ol>
            </li>
            <li>
              <h3>MUDANÇAS NA POLÍTICA DE PRIVACIDADE</h3>
              <ol>
                <li>
                  O Wedash poderá unilateralmente adicionar e/ou modificar
                  qualquer cláusula contida nestas Políticas de Privacidade. A
                  versão atualizada valerá para o uso da plataforma realizada a
                  partir de sua publicação. A continuidade de acesso ou
                  utilização do site, depois da divulgação, confirmará a
                  vigência das novas Políticas de Privacidade pelos Usuários.
                </li>
                <li>
                  Caso a mudança efetuada necessite de consentimento do Usuário,
                  será apresentada a opção de aceitar de forma livre, inequívoca
                  e informada o novo texto ou recusá-lo.
                </li>
                <li>
                  Caso o Usuário não esteja de acordo com a alteração, poderá
                  não fornecer consentimento para atos específicos ou poderá
                  rescindir totalmente seu vínculo com o Wedash. Essa rescisão
                  não eximirá, no entanto, o Usuário de cumprir com todas as
                  obrigações assumidas sob as versões precedentes das Políticas
                  de Privacidade.
                </li>
              </ol>
            </li>

            <li>
              <h3>
                CANAL DE COMUNICAÇÃO SOBRE PRIVACIDADE E DISPOSIÇÕES GERAIS
              </h3>

              <ol>
                <li>
                  O Wedash informa que, por ser agente de tratamento de pequeno
                  porte, está dispensada da nomeação de um encarregado pelo
                  tratamento de dados pessoais, nos termos da Resolução CD/ANPD
                  nº 2 de 27 de janeiro de 2022.
                </li>

                <li>
                  Todavia, ressaltamos que a sua privacidade continua sendo
                  nossa prioridade, por isso disponibilizamos o seguinte canal
                  para comunicação sobre qualquer assunto envolvendo dados
                  pessoais{' '}
                  <a href="mailto:contato@wedash.digital">
                    contato@wedash.digital
                  </a>
                  .
                </li>

                <li>
                  O Wedash dispõe de um texto específico para regular a licença
                  de uso, os direitos, deveres, garantias e disposições gerais:
                  os Termos de Uso. Todos esses documentos integram
                  inseparavelmente estas Políticas de Privacidade.
                </li>
              </ol>
            </li>

            <li>
              <h3>CONTATO</h3>

              <ol>
                <li>
                  O Wedash disponibiliza o seguinte canal para receber todas as
                  comunicações que os Usuários desejarem fazer por meio do
                  e-mail{' '}
                  <a href="mailto:contato@wedash.digital">
                    contato@wedash.digital
                  </a>
                  .
                </li>
              </ol>
            </li>
          </ol>
        </section>
      </S.Container>
      <Footer
        logoSrc={WhiteWeDash}
        logoAlt="Logo da empresa em duas linhas alinhadas a esquerda, na primeira linha está escrito we.dash e na segunda linha está escrito by bowe"
        logoScroll="header"
      />
    </>
  );
};

export default Contact;
