export const headerOptions = [
  { label: 'Sobre', pathname: '/', value: 'sobre', redirect: false },
  { label: 'Produto', pathname: '/', value: 'produto', redirect: false },
  {
    label: 'Diferenciais',
    pathname: '/',
    value: 'diferenciais',
    redirect: false,
  },
  { label: 'Planos', pathname: '/', value: 'planos', redirect: false },
  { label: 'Contato', pathname: '/contato', redirect: true },
  { label: 'FAQ', pathname: '/faq/', redirect: true },
];
